@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
html, body {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, a, .button, .text-input input,
.text-item-link {
  color: rgb(70, 70, 70);
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

@media print, (min-device-width: 1025px) {
  body {
    -webkit-scroll-snap-type: y;
        -ms-scroll-snap-type: y;
            scroll-snap-type: y;
  }
}
html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}
* {
  box-sizing: border-box;
}

.button, input, .form-input, .dropdown.selection {
  outline: none;
  -webkit-appearance: none;
}

.button, .text-input input, .text-input textarea, textarea, .date-input input {
  border-radius: 0px;
  padding: 0.7rem 1rem;
  border: none;
  font-size: 1rem;
}

.label, label {
  padding: 0.5rem 1rem;
  display: block;
}

.form-input {
  display: inline-block;
  vertical-align: bottom;
}

.text-input input, .button {
  border: 0px solid #ddd;
}

.date-input input:focus, .text-input input:focus {
  border: 0px solid #cccccc;
}

.text-input input, .text-input textarea, .date-input input, .date-input textarea {
  background-color: #F5F5F5;
}

.button {
  background-color: black;
  color: white;
}

.radio-input input {
  background-color: #eeeeee;
}
.radio-input input:checked {
  background-color: #999;
  border-color: #999;
}
.radio-input input:not(:checked):hover {
  background-color: #e6e6e6;
}
.popup {
  display: flex;
  position: fixed;
  z-index: 54;
  margin: 0;
  padding: 0;
}
.popup.type-pane, .popup.type-fitted {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.popup.type-pane {
  flex-direction: row;
}
.popup.type-pane.position-left {
  justify-content: flex-start;
}
.popup.type-pane.position-right {
  justify-content: flex-end;
}
.popup.type-pane .popup-window {
  max-width: calc(100% - 3rem);
  width: 40rem;
  height: 100%;
}
.popup.type-fitted {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 801px) {
  .popup .popup-close-button {
    position: absolute;
  }
  .popup.type-pane .popup-close-button {
    right: 0.1rem;
  }
  .popup.type-fitted .popup-close-button {
    top: 0.5rem;
    right: 0.5rem;
  }
  .popup.type-corner {
    -webkit-animation-name: slide-in-from-bottom;
            animation-name: slide-in-from-bottom;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  .popup.type-corner .popup-close-button {
    top: 0.5rem;
    right: 0.5rem;
  }
  .popup.type-corner.position-left {
    left: 2rem;
  }
  .popup.type-corner.position-right {
    right: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .popup.type-pane .popup-close-button, .popup.type-fitted .popup-close-button {
    margin-left: auto;
    position: relative;
    top: -1rem;
    left: 0.5rem;
  }
  .popup.type-corner {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  .popup.type-corner .popup-window {
    width: 80%;
    height: auto;
    margin: auto;
  }
  .popup.type-corner .popup-window .popup-close-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
}
@-webkit-keyframes slide-in-from-bottom {
  from {
    bottom: -100%;
  }
  to {
    bottom: 2rem;
  }
}
@keyframes slide-in-from-bottom {
  from {
    bottom: -100%;
  }
  to {
    bottom: 2rem;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.popup .popup-window {
  position: relative;
  background-color: #FFFFFF;
  overflow: auto;
}
.popup .popup-window .popup-close-button {
  height: 3em;
  width: 3em;
  cursor: pointer;
  z-index: 1;
}
.app-page {
  height: 100%;
  min-height: 100%;
}
.app-page .page-sections {
  height: 100%;
  min-height: 100%;
  overflow: auto;
}
@media only screen and (max-device-width: 1024px) {
  .app-page .page-sections {
    cursor: pointer;
    -webkit-overflow-scrolling: touch;
  }
}
.footer {
  margin-top: 1.7rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
.footer .careers-link, .footer .private-policy-link, .footer .tracking-options, .footer .sf-credit {
  font-size: 0.7rem;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 0.5rem;
  color: rgb(100, 100, 100);
}
.footer .careers-link:hover, .footer .private-policy-link:hover, .footer .tracking-options:hover, .footer .sf-credit:hover {
  color: rgb(50, 50, 50);
}
.footer .careers-link a, .footer .private-policy-link a, .footer .tracking-options a, .footer .sf-credit a {
  color: inherit;
}
.page-section {
  position: relative;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
}
.page-section.layout-text {
  opacity: 0.75;
}
.page-section .page-section-logo-text {
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 3.5rem;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.page-section .page-section-logo-text .logo-page-title {
  opacity: 0.6;
}
.page-section .press .press-page-title, .page-section .press .awards-page-title, .page-section .awards .press-page-title, .page-section .awards .awards-page-title {
  letter-spacing: 3px;
  font-size: 2rem;
  text-transform: uppercase;
}
.page-section .press .press-page-footer .email, .page-section .press .awards-page-footer .email, .page-section .awards .press-page-footer .email, .page-section .awards .awards-page-footer .email {
  line-height: 1.6;
  color: rgb(119, 152, 173);
}
.page-section .press .press-page-footer .email:hover, .page-section .press .awards-page-footer .email:hover, .page-section .awards .press-page-footer .email:hover, .page-section .awards .awards-page-footer .email:hover {
  color: rgb(80, 112, 133);
}
.page-section .project-stories .tiles-container, .page-section .showrooms .tiles-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.page-section .project-stories .tiles-container .tile, .page-section .showrooms .tiles-container .tile {
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}
.page-section .project-stories .tiles-container .tile:not(:last-child), .page-section .showrooms .tiles-container .tile:not(:last-child) {
  margin-bottom: 2rem;
}
.page-section .project-stories .tiles-container .tile .tile-title, .page-section .showrooms .tiles-container .tile .tile-title {
  margin: auto;
  color: #FFF;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.page-section .project-stories .tiles-container .tile .tile-title > *, .page-section .showrooms .tiles-container .tile .tile-title > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.page-section .project-stories .tiles-container .tile .tile-title .tile-title-state, .page-section .project-stories .tiles-container .tile .tile-title .tile-title-coming-soon, .page-section .showrooms .tiles-container .tile .tile-title .tile-title-state, .page-section .showrooms .tiles-container .tile .tile-title .tile-title-coming-soon {
  font-size: 50%;
  opacity: 0.7;
}
.page-section .project-stories .tiles-container .tile .tile-image-container, .page-section .showrooms .tiles-container .tile .tile-image-container {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.page-section .project-stories .tiles-container .tile .tile-image-container .tile-image, .page-section .showrooms .tiles-container .tile .tile-image-container .tile-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: -webkit-filter ease 0.5s;
  transition: -webkit-filter ease 0.5s;
  transition: filter ease 0.5s;
  transition: filter ease 0.5s, -webkit-filter ease 0.5s;
}
.page-section:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
@media print, (min-width: 801px) {
  .page-section:not(.heightAuto) {
    height: 100%;
    min-height: 48rem;
  }
  .page-section:not(:first-child).layout-text-left-grid-right .page-section-logo-text {
    display: none;
  }
  .page-section.hideLogo {
    padding-bottom: 2rem;
  }
  .page-section.hideLogo .page-section-logo-text {
    display: none;
  }
  .page-section.text-style-branding .page-section-text .text-content {
    max-width: 25rem;
    font-size: 1rem;
  }
  .page-section.text-style-details:not(.layout-text):not(.layout-media-top-text-bottom) .page-section-text .text-content {
    max-width: 25rem;
    padding-left: 2rem;
  }
  .page-section.text-style-standard:not(.layout-text):not(.layout-media-top-text-bottom) .page-section-text {
    font-size: 1.8rem;
  }
  .page-section.text-style-standard:not(.layout-text):not(.layout-media-top-text-bottom) .page-section-text .text-content {
    padding-left: 2rem;
  }
  .page-section.heightAuto.layout-text {
    padding-bottom: 10rem;
  }
  .page-section:not(.heightAuto).layout-text {
    min-height: 100%;
  }
  .page-section.layout-text {
    padding: 2rem;
    height: auto;
  }
  .page-section.layout-text:not(.hideLogo):first-child {
    padding-bottom: 7rem;
  }
  .page-section.layout-text.hideLogo:first-child {
    padding-bottom: 2rem;
  }
  .page-section.layout-text .page-section-text {
    font-size: 1.8rem;
    height: 100%;
  }
  .page-section.layout-text .page-section-text .text-item-link {
    font-size: 0.8rem;
  }
  .page-section.layout-text-left .page-section-text {
    height: 100%;
    width: 50%;
  }
  .page-section.layout-text-left-media-right .page-section-text {
    padding-right: 4rem;
  }
  .page-section.layout-text-left-media-right .media-container .page-section-medium:not(.orientation-vertical) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .page-section.layout-text-left-media-right .vertical-split:not(.double-media) .page-section-medium.orientation-square .medium-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  }
  .page-section.layout-text-left-media-right .vertical-split:not(.double-media) .page-section-medium.orientation-horizontal .medium-image {
    height: auto;
    background-image: none !important;
  }
  .page-section.layout-text-left-media-right .vertical-split:not(.double-media) .page-section-medium.orientation-horizontal .medium-image img {
    opacity: 1;
    height: auto;
    position: relative;
  }
  .page-section.layout-text-left-grid-right .page-section-text {
    padding-right: 4rem;
  }
  .page-section.layout-text-left-grid-right .page-section-grid {
    padding-left: 0;
  }
  .page-section.layout-media-top-text-bottom .horizontal-flex-split {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .page-section.layout-media-top-text-bottom .horizontal-flex-split .page-section-medium {
    height: auto;
    flex: 1 1;
  }
  .page-section.layout-media-top-text-bottom .horizontal-flex-split .page-section-text {
    padding-left: 2rem;
  }
  .page-section.layout-media-top-text-bottom .horizontal-flex-split .page-section-text .text-content {
    font-size: 1rem;
  }
  .page-section.layout-media {
    padding-top: 2rem;
  }
  .page-section.layout-media video {
    object-fit: cover;
  }
  .page-section.layout-accordion-with-media {
    min-height: 45rem;
  }
  .page-section.layout-accordion-with-media .accordion-container {
    display: flex;
    position: relative;
    align-items: flex-start;
    padding: 4rem;
  }
  .page-section.layout-accordion-with-media .accordion-container .accordion {
    width: 25rem;
    margin-bottom: 4rem;
  }
  .page-section.layout-accordion-with-media .media-container {
    height: 100vh !important;
    min-height: 45rem;
  }
  .page-section.layout-accordion-with-media .media-container .page-section-medium.orientation-square .medium-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  }
  .page-section.layout-accordion-with-media .media-container .page-section-medium.orientation-horizontal .medium-image {
    height: auto;
    background-image: none !important;
  }
  .page-section.layout-accordion-with-media .media-container .page-section-medium.orientation-horizontal .medium-image img {
    opacity: 1;
    height: auto;
    position: relative;
  }
  .page-section.layout-double-media {
    padding-top: 2rem;
  }
  .page-section.layout-double-media .media-container.left .medium-image {
    background-position: right center !important;
  }
  .page-section.layout-double-media .media-container.right {
    margin-left: 1px;
  }
  .page-section.layout-double-media .media-container.right .medium-image {
    background-position: left center !important;
  }
  .page-section.layout-double-media .page-section-medium.orientation-square .medium-image, .page-section.layout-double-media .page-section-medium.orientation-vertical .medium-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  }
  .page-section .text-item-link {
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
  }
  .page-section .page-section-logo-text {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  .page-section .long-paragraph {
    font-size: 1rem;
    width: 75%;
  }
  .page-section .vertical-split {
    height: 100%;
    display: flex;
  }
  .page-section .vertical-split .page-section-logo-text {
    padding-left: 2rem;
  }
  .page-section .vertical-split .page-section-text, .page-section .vertical-split .media-container, .page-section .vertical-split .page-section-grid, .page-section .vertical-split .accordion-container {
    height: 100%;
    width: 50%;
  }
  .page-section .press .press-page-title, .page-section .press .press-page-footer,
  .page-section .press .awards-page-title, .page-section .press .awards-page-footer, .page-section .awards .press-page-title, .page-section .awards .press-page-footer,
  .page-section .awards .awards-page-title, .page-section .awards .awards-page-footer {
    padding: 2rem 2rem;
  }
  .page-section .press .press-quotes, .page-section .press .awards, .page-section .awards .press-quotes, .page-section .awards .awards {
    margin-bottom: 4rem;
  }
  .page-section .press .press-quotes .page-section-text, .page-section .press .awards .page-section-text, .page-section .awards .press-quotes .page-section-text, .page-section .awards .awards .page-section-text {
    padding: 2rem 2rem;
    padding-right: 10vw;
    width: 50%;
    display: inline-block;
  }
  .page-section .project-stories .tiles-container, .page-section .showrooms .tiles-container {
    padding: 0 2rem;
  }
  .page-section .project-stories .tiles-container .tile, .page-section .showrooms .tiles-container .tile {
    height: 100%;
    min-height: 48rem;
  }
  .page-section .project-stories .tiles-container .tile:hover, .page-section .showrooms .tiles-container .tile:hover {
    cursor: pointer;
  }
  .page-section .project-stories .tiles-container .tile:hover .tile-title, .page-section .showrooms .tiles-container .tile:hover .tile-title {
    opacity: 0.85;
  }
  .page-section .project-stories .tiles-container .tile:hover .tile-image-container .tile-image, .page-section .showrooms .tiles-container .tile:hover .tile-image-container .tile-image {
    -webkit-filter: brightness(75%) !important;
            filter: brightness(75%) !important;
  }
  .page-section .project-stories .tiles-container .tile .tile-title, .page-section .showrooms .tiles-container .tile .tile-title {
    font-size: 5.5vw;
    letter-spacing: 0.4vw;
    padding: 0 4rem;
  }
  .page-section .project-stories .tiles-container .tile .tile-image-container .tile-image, .page-section .showrooms .tiles-container .tile .tile-image-container .tile-image {
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
  }
}
@media only screen and (max-width: 800px) {
  .page-section {
    padding: 1rem;
  }
  .page-section:first-child:not(.layout-media):not(.layout-media-top-text-bottom):not(.layout-text) {
    min-height: 100%;
  }
  .page-section:not(:first-child) .page-section-logo-text {
    display: none;
  }
  .page-section.text-style-branding .page-section-text .text-content {
    font-size: 1rem;
  }
  .page-section.text-style-details .page-section-text .text-content .long-paragraph {
    font-size: 1rem;
  }
  .page-section.text-style-details .page-section-text .text-content .text-item-link {
    font-size: 0.8rem;
  }
  .page-section.layout-text-left-media-right .vertical-split, .page-section.layout-accordion-with-media .vertical-split {
    display: flex;
    flex-direction: column-reverse;
  }
  .page-section.layout-accordion-with-media .page-section-logo-text {
    display: none;
  }
  .page-section.layout-accordion-with-media .accordion {
    margin-top: 1rem;
  }
  .page-section.layout-text:first-child {
    padding-bottom: 5rem;
  }
  .page-section.layout-text .page-section-text {
    font-size: 1.8rem;
    height: 100%;
  }
}
@media only screen and (max-width: 800px) and (max-device-width: 1024px) {
  .page-section.layout-text .page-section-text {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 800px) {
  .page-section:not(.layout-text) .page-section-logo-text {
    position: relative;
    bottom: 0px;
    margin-top: 1rem;
  }
  .page-section .page-section-medium {
    margin-bottom: 1px;
  }
  .page-section .page-section-text, .page-section .page-section-medium {
    display: block;
    width: 100%;
  }
  .page-section .press .press-page-title, .page-section .press .press-page-footer,
  .page-section .press .awards-page-title, .page-section .press .awards-page-footer, .page-section .awards .press-page-title, .page-section .awards .press-page-footer,
  .page-section .awards .awards-page-title, .page-section .awards .awards-page-footer {
    margin-bottom: 2rem;
  }
  .page-section .press .press-quotes, .page-section .press .awards, .page-section .awards .press-quotes, .page-section .awards .awards {
    margin-bottom: 4rem;
  }
  .page-section .press .press-quotes .page-section-text, .page-section .press .awards .page-section-text, .page-section .awards .press-quotes .page-section-text, .page-section .awards .awards .page-section-text {
    margin-bottom: 2rem;
  }
  .page-section .project-stories .tiles-container .tile, .page-section .showrooms .tiles-container .tile {
    height: 40vw;
    min-height: 20rem;
  }
  .page-section .project-stories .tiles-container .tile .tile-title, .page-section .showrooms .tiles-container .tile .tile-title {
    font-size: 8vw;
    letter-spacing: 0.75vw;
    padding: 0 1rem;
  }
  .page-section .project-stories .tiles-container .tile .tile-image-container .tile-image, .page-section .showrooms .tiles-container .tile .tile-image-container .tile-image {
    -webkit-filter: brightness(60%);
            filter: brightness(60%);
  }
}
.page-section-medium {
  overflow: hidden;
  position: relative;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
}
.page-section-medium .medium-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}
.page-section-medium .medium-overlay .project-story-title-container {
  margin: auto;
  color: #FFF;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.page-section-medium .medium-image {
  width: 100%;
  height: 100%;
}
.page-section-medium .medium-image img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .page-section-medium {
    height: auto;
  }
  .page-section-medium .medium-overlay .project-story-title-container {
    padding: 0 1rem;
  }
  .page-section-medium .medium-overlay .project-story-title-container .project-story-title {
    font-size: 7vw;
    letter-spacing: 0.7vw;
    margin-bottom: 1vw;
  }
  .page-section-medium .medium-overlay .project-story-title-container .project-story-location {
    font-size: 4vw;
    letter-spacing: 0.3vw;
  }
  .page-section-medium .medium-image {
    background: none !important;
  }
  .page-section-medium video {
    width: 100%;
  }
}
@media print, (min-width: 801px) {
  .page-section-medium {
    height: 100%;
  }
  .page-section-medium .medium-overlay .project-story-title-container {
    padding: 0 4rem;
  }
  .page-section-medium .medium-overlay .project-story-title-container .project-story-title {
    font-size: 5.5vw;
    letter-spacing: 0.4vw;
    margin-bottom: 2rem;
  }
  .page-section-medium .medium-overlay .project-story-title-container .project-story-location {
    font-size: 3vw;
    letter-spacing: 0.2vw;
  }
  .page-section-medium .medium-image {
    background-size: cover;
    background-position: center;
  }
  .page-section-medium .medium-image img {
    height: 100%;
    opacity: 0;
    position: absolute;
  }
  .page-section-medium.position-top .medium-image {
    background-position: top;
  }
  .page-section-medium.position-bottom .medium-image {
    background-position: bottom;
  }
  .page-section-medium .medium-video {
    width: 100%;
    height: 100%;
  }
  .page-section-medium .medium-video video {
    width: 100%;
    height: 100%;
  }
}
.page-section-text {
  font-size: 1rem;
}
.page-section-text .text-container {
  width: 100%;
  height: 100%;
  display: flex;
  line-height: 1.4;
}
.page-section-text .text-container .text-content {
  white-space: pre-line;
}
.page-section-text .text-container .text-content .page-section-text-item {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.page-section-text .text-container .text-content .page-section-text-item:not(.is-visible) {
  opacity: 0;
}
.page-section-text .text-container .text-content .text-item-plain:not(:last-child) {
  margin-bottom: 1em;
}
.page-section-text .text-container .text-content .text-item-heading {
  font-size: 1.2em;
  margin-bottom: 1em;
  font-weight: bold;
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-sub-heading {
  color: rgb(119, 152, 173);
  margin-top: 1em;
  margin-bottom: 1em;
}
.page-section-text .text-container .text-content .text-item-larger-text {
  font-size: 110%;
}
.page-section-text .text-container .text-content .text-item-larger-text-space-after {
  font-size: 110%;
  margin-bottom: 1em;
}
.page-section-text .text-container .text-content .text-item-larger-text-space-after.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-smaller-text {
  font-size: 75%;
}
.page-section-text .text-container .text-content .text-item-smaller-text.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-smaller-text-space-after {
  margin-bottom: 1em;
  font-size: 75%;
}
.page-section-text .text-container .text-content .text-item-smaller-text-space-after.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-link {
  display: block;
  text-transform: uppercase;
  line-height: 1.6;
}
.page-section-text .text-container .text-content .text-item-link.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-link.blue:hover {
  color: rgb(80, 112, 133);
}
.page-section-text .text-container .text-content .text-item-link:not(.blue) {
  color: rgb(180, 180, 180);
}
.page-section-text .text-container .text-content .text-item-link:not(.blue):hover {
  color: rgb(139, 138, 135);
}
.page-section-text .text-container .text-content .text-item-link.next-page {
  margin-top: 3em;
}
.page-section-text .text-container .text-content .text-item-link.larger-text {
  font-size: 80%;
}
.page-section-text .text-container .text-content a:not(.text-item-link), .page-section-text .text-container .text-content .pseudo-link {
  cursor: pointer;
  text-decoration: underline;
}
.page-section-text .text-container .text-content a.no-underline {
  text-decoration: none;
}
.page-section-text .text-container .text-content a.no-underline.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content a.no-underline.blue:hover {
  color: rgb(80, 112, 133);
}
.page-section-text .text-container .text-content a.no-underline:not(.blue) {
  color: rgb(180, 180, 180);
}
.page-section-text .text-container .text-content a.no-underline:not(.blue):hover {
  color: rgb(139, 138, 135);
}
.page-section-text .text-container .text-content a.phone {
  text-decoration: none;
}
@media only screen and (max-width: 800px) {
  .page-section-text .text-container .text-content {
    padding: 2rem;
  }
}
@media print, (min-width: 801px) {
  .page-section-text .text-container .text-content {
    padding: 2rem;
  }
}
.page-section-text .text-container .quote-content, .page-section-text .text-container .award-content {
  width: 100%;
}
.page-section-text .text-container .quote-content a.blue, .page-section-text .text-container .award-content a.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .quote-content a.blue:hover, .page-section-text .text-container .award-content a.blue:hover {
  color: rgb(80, 112, 133);
}
.page-section-text .text-container .quote-content .quote-author {
  margin-top: 0.75rem;
  text-align: right;
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .quote-content .quote-author:hover {
  color: rgb(80, 112, 133);
}
.page-section-text .text-container .award-content .award-media {
  height: 5rem;
  margin-bottom: 1rem;
}
.page-section-text .text-container .award-content .award-media img {
  height: 100%;
}
.page-section-text .text-container .award-content .award-title-container {
  margin-bottom: 1rem;
}
.page-section-text .text-container .award-content .award-title-container .award-title {
  margin-top: 0.75rem;
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .award-content .award-title-container .award-title:hover {
  color: rgb(80, 112, 133);
}
@media print, (min-width: 801px) {
  .page-section-text .text-container.align-y-top {
    align-items: flex-start;
  }
  .page-section-text .text-container.align-y-center {
    align-items: center;
  }
  .page-section-text .text-container.align-y-bottom {
    align-items: flex-end;
  }
  .page-section-text .text-container.align-x-left {
    justify-content: flex-start;
  }
  .page-section-text .text-container.align-x-center {
    justify-content: center;
    position: relative;
  }
  .page-section-text .text-container.align-x-right {
    justify-content: flex-end;
  }
}
@charset "UTF-8";
.page-section.layout-text-left-grid-right:first-child .page-section-grid {
  margin-bottom: 2rem;
}

.page-section-grid {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.page-section-grid:not(.cell-size-mode-auto) .cell-thumbnail img {
  width: 100%;
}
.page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail img {
  display: block;
}
.page-section-grid:not(.all-cells-are-loaded) .grid-cell, .page-section-grid:not(.all-cells-are-loaded) .cell-group-title {
  opacity: 0;
}
.page-section-grid .grid-cell-details {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}
@-webkit-keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.page-section-grid .grid-cell-details.is-visible {
  -webkit-animation: show 0.5s;
          animation: show 0.5s;
}
.page-section-grid .grid-cell-details:not(.is-visible) {
  opacity: 0;
  -webkit-animation: hide 0.5s;
          animation: hide 0.5s;
}
.page-section-grid .grid-cell-details .details-background {
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  position: relative;
}
.page-section-grid .grid-cell-details .details-background .details-pane {
  height: 100%;
  background-color: white;
  color: #000;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 0px;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-header {
  display: flex;
  border-bottom: 1px solid #ddd;
  min-height: 4rem;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-header .cell-title {
  font-size: 1.5rem;
  text-transform: lowercase;
  padding: 1rem 2rem;
  width: 100%;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-content {
  overflow: auto;
  flex-grow: 1;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-content .constraints-container {
  max-height: 9rem;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-content .details-notes {
  display: inline-block;
  margin: 1rem 0rem;
  padding-left: 3rem;
  overflow-wrap: break-word;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-content .details-notes .details-note {
  text-transform: lowercase;
  padding: 0.2rem 0rem;
}
.page-section-grid .cell-groups .cell-group .cell-group-title {
  padding: 0.5rem 0;
  font-weight: bold;
  font-size: 0.9rem;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell {
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
  position: relative;
  margin-bottom: 2rem;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell.has-details {
  cursor: pointer;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell.has-details:hover .cell-title {
  text-decoration: underline;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-title {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  height: 2rem;
  text-transform: lowercase;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-thumbnail.is-loaded img {
  max-width: 100%;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-thumbnail:not(.is-loaded) img {
  position: absolute;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-thumbnail.has-error img {
  display: none;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-thumbnail.has-border img {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.page-section-grid.cell-size-mode-small .cell-group-cells, .page-section-grid.cell-size-mode-auto .cell-group-cells {
  max-width: 42rem;
}
.page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
  max-width: 9rem;
}
.page-section-grid.cell-size-mode-medium .cell-group-cells {
  max-width: 49rem;
}
.page-section-grid.cell-size-mode-medium .grid-cell {
  max-width: 15rem;
}
.page-section-grid.cell-size-mode-large .cell-group-cells {
  max-width: 48rem;
}
.page-section-grid.cell-size-mode-large .grid-cell {
  max-width: 20rem;
}
@media print, (min-width: 801px) {
  .page-section-grid {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }
  .page-section-grid .details-pane {
    width: 40rem;
  }
  .page-section-grid .details-pane .details-slideshow {
    height: 25rem;
  }
}
@media print and (min-width: 1001px), (min-width: 801px) and (min-width: 1001px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 4 - 3 * 2rem / 4);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(4n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(4n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell {
    width: calc(100% / 3 - 2 * 2rem / 3);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media print and (min-width: 801px) and (max-width: 1000px), (min-width: 801px) and (min-width: 801px) and (max-width: 1000px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 3 - 2 * 2rem / 3);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(3n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-width: 800px) {
  .page-section-grid .details-pane {
    width: calc(100% - 5rem);
  }
  .page-section-grid .details-pane .details-slideshow {
    height: 20rem;
  }
  .page-section-grid .details-pane .details-header .cell-close-details {
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    opacity: 0.3;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .page-section-grid .details-pane .details-header .cell-close-details:hover {
    color: rgba(0, 0, 0, 0.8);
  }
  .page-section-grid .details-pane .details-header .cell-close-details::after {
    content: "✕";
  }
}
@media only screen and (max-width: 800px) and (min-width: 701px) and (max-width: 800px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 4 - 3 * 2rem / 4);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(4n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(4n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell {
    width: calc(100% / 3 - 2 * 2rem / 3);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-width: 800px) and (min-width: 501px) and (max-width: 700px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 3 - 2 * 2rem / 3);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(3n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-width: 800px) and (min-width: 351px) and (max-width: 500px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(2n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell, .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(1n)), .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-width: 800px) and (max-width: 350px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(1n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell, .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(1n)), .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-device-width: 1024px) and (orientation: landscape) and (orientation: landscape) {
  .page-section-grid .details-pane {
    flex-direction: row;
  }
  .page-section-grid .details-pane .constraints-container {
    padding: 1rem;
    padding-left: 0;
    width: 15rem;
    left: 0px;
  }
  .page-section-grid .details-pane .details-slideshow {
    position: absolute;
    width: calc(100% - 15rem);
    height: calc(100% - 4rem);
    right: 0px;
    bottom: 0px;
  }
  .page-section-grid .details-pane .details-notes .details-note {
    width: 11rem;
  }
}
.constraints {
  display: block;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  padding: 1rem 2rem;
}
.constraints .constraint-title, .constraints .constraint-value, .constraints .constraint-values, .constraints .step-line {
  display: inline-block;
  vertical-align: middle;
}
.constraints .constraint {
  padding: 0.2rem 0;
}
.constraints .constraint .constraint-title {
  text-transform: lowercase;
  width: 1.5rem;
  opacity: 0.5;
  font-size: 1.3rem;
  margin-right: 0.5rem;
}
.constraints .constraint .constraint-values {
  white-space: normal;
  max-width: calc(100% - 2rem);
}
.constraints .constraint .constraint-values .constraint-value {
  font-size: 1rem;
  white-space: nowrap;
  line-height: 1.5;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-min, .constraints .constraint .constraint-values .constraint-value.constraint-value-max, .constraints .constraint .constraint-values .constraint-value.constraint-value-fixed {
  padding: 0.1rem 0.6rem;
  border: 1px solid black;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-min::after, .constraints .constraint .constraint-values .constraint-value.constraint-value-max::after, .constraints .constraint .constraint-values .constraint-value.constraint-value-fixed::after {
  content: '"';
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-step {
  padding-left: 0.5rem;
  text-align: center;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-fixed {
  margin-left: -1px;
  margin-top: -1px;
  min-width: 2.6rem;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-line {
  height: 0;
  text-align: center;
  width: 0.4rem;
  border-bottom: 1px solid black;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-antecedent {
  padding: 0rem 0.3rem;
  text-align: center;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-antecedent.fixed::after {
  content: "";
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-antecedent.fixed:nth-last-child(3)::after {
  content: "or";
  padding: 0rem 0.5rem;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-antecedent.fixed:last-child {
  display: none;
}
.slideshow {
  width: 100%;
  height: 100%;
}
.slideshow.is-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0px;
  right: 0px;
  z-index: 3;
}
.slideshow .slideshow-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.slideshow .slideshow-content .slideshow-media, .slideshow .slideshow-content .slideshow-slides {
  height: 100%;
  width: 100%;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium, .slideshow .slideshow-content .slideshow-media .slideshow-slide, .slideshow .slideshow-content .slideshow-slides .slideshow-medium, .slideshow .slideshow-content .slideshow-slides .slideshow-slide {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium:not(.active), .slideshow .slideshow-content .slideshow-media .slideshow-slide:not(.active), .slideshow .slideshow-content .slideshow-slides .slideshow-medium:not(.active), .slideshow .slideshow-content .slideshow-slides .slideshow-slide:not(.active) {
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium {
  background-position: center;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium img {
  height: 100%;
  width: 100%;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium.size-mode-contain img {
  object-fit: contain;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium.size-mode-cover img {
  object-fit: cover;
}
.slideshow .slideshow-content .slideshow-controls {
  position: absolute;
  display: flex;
}
.slideshow .slideshow-content .slideshow-controls.show-background {
  background-color: rgba(0, 0, 0, 0.3);
}
.slideshow .slideshow-content .slideshow-controls.position-right, .slideshow .slideshow-content .slideshow-controls.position-left {
  top: 0px;
  width: 3rem;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-dots, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-dots {
  flex-direction: column;
  padding: 0.5rem 0;
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-prev, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-prev {
  padding-top: 0.7rem;
  padding-bottom: 0.4rem;
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-prev .arrow, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-prev .arrow {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-next, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-next {
  padding-top: 0.4rem;
  padding-bottom: 0.7rem;
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-next .arrow, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-next .arrow {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.slideshow .slideshow-content .slideshow-controls.position-right {
  right: 0px;
}
.slideshow .slideshow-content .slideshow-controls.position-left {
  left: 0px;
}
.slideshow .slideshow-content .slideshow-controls.position-top, .slideshow .slideshow-content .slideshow-controls.position-bottom {
  left: 0px;
  height: 3rem;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-dots, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-dots {
  padding: 0 0.5rem;
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-prev, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-prev {
  padding-left: 0.7rem;
  padding-right: 0.4rem;
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-prev .arrow, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-prev .arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-next, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-next {
  padding-left: 0.4rem;
  padding-right: 0.7rem;
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-next .arrow, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-next .arrow {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.slideshow .slideshow-content .slideshow-controls.position-top {
  top: 15px;
}
.slideshow .slideshow-content .slideshow-controls.position-bottom {
  bottom: 15px;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots {
  position: relative;
  display: flex;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots .slideshow-dot-container {
  padding: 0.4rem;
  cursor: pointer;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots .slideshow-dot-container:hover .slideshow-dot {
  background-color: rgba(255, 255, 255, 0.3);
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots .slideshow-dot-container.active .slideshow-dot {
  background-color: white;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots .slideshow-dot-container .slideshow-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid white;
  margin: auto;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-control-button {
  cursor: pointer;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-control-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.slideshow .slideshow-content .slideshow-controls .slideshow-control-button .arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  width: 0;
  height: 0;
  padding: 5px;
  margin: auto;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-prev, .slideshow .slideshow-content .slideshow-controls .slideshow-next {
  height: 2rem;
  display: flex;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-toggle-fullscreen {
  margin-top: 1.5rem;
  padding: 0.9rem;
  width: 3rem;
  height: 3rem;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-toggle-fullscreen img {
  height: 100%;
  width: 100%;
}
@media only screen and (max-device-width: 1024px) {
  .slideshow.is-fullscreen {
    width: calc(100% - 5rem);
  }
}
.accordion .accordion-title {
  letter-spacing: 3px;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.accordion .accordion-description {
  font-size: 1rem;
  margin-bottom: 2rem;
}
.accordion .accordion-description a[href^="mailto:"] {
  cursor: pointer;
}
.accordion .accordion-description a[href^="mailto:"].blue {
  color: rgb(119, 152, 173);
}
.accordion .accordion-description a[href^="mailto:"].blue:hover {
  color: rgb(80, 112, 133);
}
.accordion .accordion-description a[href^="mailto:"]:not(.blue) {
  color: rgb(180, 180, 180);
}
.accordion .accordion-description a[href^="mailto:"]:not(.blue):hover {
  color: rgb(139, 138, 135);
}
.accordion .accordion-section:not(.is-expanded) .accordion-section-content {
  display: none;
  visibility: hidden;
}
.accordion .accordion-section.is-expanded .accordion-section-title {
  color: #7798ad;
}
.accordion .accordion-section .accordion-section-title {
  padding: 0.75rem 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-size: 1rem;
  border-bottom: 1px solid #bbb;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.accordion .accordion-section .accordion-section-title:hover {
  opacity: 0.6;
}
.accordion .accordion-section .accordion-section-content {
  margin-bottom: 2rem;
  padding-left: 1rem;
}
.accordion .accordion-section .accordion-section-content .accordion-text-item {
  margin-bottom: 1rem;
}
.accordion .accordion-section .accordion-section-content .accordion-text-item b {
  font-weight: bold;
  opacity: 0.9;
}
.accordion .accordion-text-item-link {
  cursor: pointer;
  color: rgb(180, 180, 180);
}
.accordion .accordion-text-item-link:hover {
  color: rgb(139, 138, 135);
}
.accordion .accordion-text-item-link.next-page {
  margin-top: 3em;
  text-transform: uppercase;
}
.accordion .accordion-text-item-link.blue {
  color: rgb(119, 152, 173);
}
.accordion .accordion-text-item-link.blue:hover {
  color: rgb(80, 112, 133);
}
.accordion .accordion-text-item-link.dark-gray {
  color: rgb(70, 70, 70);
  font-size: 1rem;
}
.accordion .accordion-text-item-link.dark-gray:hover {
  color: rgb(140, 140, 140);
}
.accordion .accordion-text-item-link.next-page {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.accordion .next-page-container {
  margin-top: 5em;
  padding-left: 2rem;
}
.landing-page {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.landing-page .landing-page-frame {
  border-bottom: 1px solid #e5e5e5;
}
.landing-page .landing-page-frame, .landing-page .slideshow-slide {
  display: flex;
}
.landing-page .landing-page-frame .text-container, .landing-page .landing-page-frame .media-container, .landing-page .slideshow-slide .text-container, .landing-page .slideshow-slide .media-container {
  display: flex;
  position: relative;
}
.landing-page .landing-page-frame .text-container, .landing-page .slideshow-slide .text-container {
  flex-direction: column;
  line-height: 1.4;
  flex: 1 1;
}
.landing-page .landing-page-frame .text-container .text-item, .landing-page .slideshow-slide .text-container .text-item {
  width: 100%;
}
.landing-page .landing-page-frame .text-container .text-item a, .landing-page .landing-page-frame .text-container .text-item .discuss-a-project, .landing-page .slideshow-slide .text-container .text-item a, .landing-page .slideshow-slide .text-container .text-item .discuss-a-project {
  color: rgb(119, 152, 173);
  cursor: pointer;
}
.landing-page .landing-page-frame .text-container .text-item a:hover, .landing-page .landing-page-frame .text-container .text-item .discuss-a-project:hover, .landing-page .slideshow-slide .text-container .text-item a:hover, .landing-page .slideshow-slide .text-container .text-item .discuss-a-project:hover {
  color: rgb(80, 112, 133);
}
.landing-page .landing-page-frame .text-container .text-item.heading, .landing-page .slideshow-slide .text-container .text-item.heading {
  font-weight: bold;
  color: rgb(119, 152, 173);
}
@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.landing-page .landing-page-frame .text-container.title-text .company-name, .landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .company-name, .landing-page .slideshow-slide .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
  opacity: 0;
  -webkit-animation: fadeInAnimation 1s;
          animation: fadeInAnimation 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.landing-page .landing-page-frame .text-container.title-text .company-name, .landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .company-name, .landing-page .slideshow-slide .text-container.title-text .tag-line {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.landing-page .landing-page-frame .text-container.title-text .company-name span:nth-child(2), .landing-page .slideshow-slide .text-container.title-text .company-name span:nth-child(2) {
  opacity: 0.7;
}
.landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .tag-line {
  font-weight: bold;
}
.landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
  font-weight: bold;
}
.landing-page .landing-page-frame .media-container .image-container, .landing-page .slideshow-slide .media-container .image-container {
  height: 100%;
  width: 100%;
}
.landing-page .landing-page-frame .media-container .image-container img, .landing-page .slideshow-slide .media-container .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.landing-page .scroll-arrow-position-container {
  position: fixed;
  display: flex;
}
.landing-page .scroll-arrow-position-container .left-container {
  display: flex;
  justify-content: center;
}
.landing-page .scroll-arrow-position-container .left-container .scroll-arrow-container {
  height: 2rem;
  width: 6rem;
  display: flex;
  -webkit-transition: visibility 0s, opacity 1s;
  transition: visibility 0s, opacity 1s;
}
.landing-page .scroll-arrow-position-container .left-container .scroll-arrow-container.hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
}
.landing-page .scroll-arrow-position-container .left-container .scroll-arrow-container.visible {
  visibility: visible;
  opacity: 1;
}
.landing-page .scroll-arrow-position-container .left-container .scroll-arrow-container .arrow {
  border: solid #bbb;
  border-width: 0 2px 2px 0;
  width: 0;
  height: 0;
  padding: 5px;
  margin: auto;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  translate: 0 -2.5px;
}
@media only screen and (max-width: 800px) {
  .landing-page .landing-page-frame {
    height: 100vh;
    height: 100lvh;
    min-height: 100vw;
  }
  .landing-page .landing-page-frame, .landing-page .slideshow-slide {
    flex-direction: column-reverse;
  }
  .landing-page .landing-page-frame:not(:last-child), .landing-page .slideshow-slide:not(:last-child) {
    padding-bottom: 3rem;
  }
  .landing-page .landing-page-frame .text-container, .landing-page .slideshow-slide .text-container {
    padding: 10vw;
  }
  .landing-page .landing-page-frame .text-container.align-center, .landing-page .slideshow-slide .text-container.align-center {
    align-items: center;
  }
  .landing-page .landing-page-frame .text-container.title-text .company-name, .landing-page .slideshow-slide .text-container.title-text .company-name {
    font-size: 7.5vw;
    letter-spacing: 1.8vw;
    margin-bottom: 3vw;
  }
  .landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .tag-line {
    font-size: 3.5vw;
    margin-bottom: 9vw;
    letter-spacing: 1vw;
  }
  .landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
    font-size: 2vw;
    letter-spacing: 0.5vw;
    text-align: center;
  }
  .landing-page .landing-page-frame .text-container .text-item, .landing-page .slideshow-slide .text-container .text-item {
    max-width: 60vw;
    font-size: 3.75vw;
  }
  .landing-page .landing-page-frame .text-container .text-item.heading, .landing-page .slideshow-slide .text-container .text-item.heading {
    font-size: 5vw;
  }
  .landing-page .landing-page-frame .text-container .text-item:not(:last-child), .landing-page .slideshow-slide .text-container .text-item:not(:last-child) {
    margin-bottom: 1.5vw;
  }
  .landing-page .landing-page-frame .media-container, .landing-page .slideshow-slide .media-container {
    height: 50vh;
    height: 50lvh;
  }
  .landing-page .scroll-arrow-position-container {
    width: 100%;
    bottom: 5vw;
  }
  .landing-page .scroll-arrow-position-container .left-container {
    flex: 1 1;
  }
}
@media print, (min-width: 801px) {
  .landing-page .landing-page-frame {
    height: 100vh;
    height: 100lvh;
  }
  .landing-page .landing-page-frame, .landing-page .slideshow-slide {
    flex-direction: row;
  }
  .landing-page .landing-page-frame .text-container, .landing-page .slideshow-slide .text-container {
    min-width: 50vh;
    min-width: 50lvh;
    padding: 3vw;
    align-items: center;
    justify-content: center;
  }
  .landing-page .landing-page-frame .text-container.title-text .company-name, .landing-page .slideshow-slide .text-container.title-text .company-name {
    font-size: 2.25vw;
    margin-bottom: 1rem;
    letter-spacing: 0.75vw;
  }
  .landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .tag-line {
    font-size: 1vw;
    margin-bottom: 15%;
    letter-spacing: 0.5vw;
  }
  .landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
    font-size: 0.75vw;
    letter-spacing: 0.25vw;
  }
  .landing-page .landing-page-frame .text-container .text-item, .landing-page .slideshow-slide .text-container .text-item {
    max-width: 25rem;
    font-size: calc(8px + 0.5vw);
  }
  .landing-page .landing-page-frame .text-container .text-item.heading, .landing-page .slideshow-slide .text-container .text-item.heading {
    font-size: calc(8px + 0.75vw);
  }
  .landing-page .landing-page-frame .text-container .text-item:not(:last-child), .landing-page .slideshow-slide .text-container .text-item:not(:last-child) {
    margin-bottom: 0.5vw;
  }
  .landing-page .landing-page-frame .media-container, .landing-page .slideshow-slide .media-container {
    width: 100vh;
    width: 100lvh;
  }
  .landing-page .scroll-arrow-position-container {
    width: calc(100% - 5rem);
    bottom: 5rem;
    pointer-events: none;
  }
  .landing-page .scroll-arrow-position-container .left-container {
    min-width: 50vh;
    min-width: 50lvh;
    padding: 0 3vw;
    flex: 1 1;
    pointer-events: all;
  }
  .landing-page .scroll-arrow-position-container .right-container {
    width: 100vh;
    width: 100lvh;
  }
  .landing-page .scroll-arrow-position-container .scroll-arrow-container {
    cursor: pointer;
    left: calc(25% + 5rem);
  }
  .landing-page .scroll-arrow-position-container .scroll-arrow-container:hover .arrow {
    border-color: #777;
  }
}
.text-input {
  width: 15rem;
}
.text-input input {
  width: 100%;
}
.text-input input:hover:not(:focus) {
  opacity: 0.8;
}
@charset "UTF-8";
.dropdown-input {
  width: 15rem;
}

.dropdown.selection {
  position: relative;
  width: 100%;
}
.dropdown.selection.active > .text {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown.selection.active > .icon {
  top: 0.6rem;
}
.dropdown.selection.active > .icon:before {
  content: "▲";
}
.dropdown.selection.active .menu {
  z-index: 1;
}
.dropdown.selection:not(.active):hover {
  opacity: 0.8;
}
.dropdown.selection:not(.active) .menu {
  display: none;
}
.dropdown.selection:not(.active) > .icon {
  top: 0.8rem;
}
.dropdown.selection:not(.active) > .icon:before {
  content: "▼";
}
.dropdown.selection > .text, .dropdown.selection > .icon {
  cursor: pointer;
}
.dropdown.selection > .text {
  padding: 0.7rem 1rem;
  padding-right: 2rem;
  border-radius: 0px;
}
.dropdown.selection .text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown.selection > .icon {
  font-style: normal;
  position: absolute;
  right: 0.7rem;
  font-size: 0.7em;
  opacity: 0.6;
}
.dropdown.selection .menu {
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown.selection .menu .item:not(.active) {
  cursor: pointer;
}
.dropdown.selection .menu .item .text {
  padding: 0.7rem 1rem;
}

.theme-light .dropdown.selection.active > .text, .theme-light .dropdown.selection.active .menu {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
}
.theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  background-color: #F5F5F5;
}
.theme-light .dropdown.selection > .text {
  border: 0px solid #ddd;
}
.theme-light .dropdown.selection .menu {
  border: 0px solid #ddd;
  border-top: none;
}
.theme-light .dropdown.selection .menu .item.active {
  background-color: rgba(0, 0, 0, 0.05);
}
.theme-light .dropdown.selection .menu .item:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.radio-input .radio {
  display: flex;
}
.radio-input .radio:not(:last-child) {
  margin-bottom: 0.3rem;
}
.radio-input .radio input {
  flex-shrink: 0;
  margin: 1px;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.radio-input .radio label {
  display: inline-block;
  padding: 0;
  padding-left: 1rem;
}
.radio-input .radio label:hover:not(:focus) {
  opacity: 0.8;
}

.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.7;
}

.theme-light .button, .theme-light .text-input input {
  font-size: 0.9rem;
}
.theme-light .button, .theme-light .text-input input, .theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  border: 1px solid #ddd;
}
.theme-light .text-input input {
  width: 100%;
}
.theme-light label, .theme-light .label {
  padding: 0.4rem 1rem;
  padding-top: 0.6rem;
}

.ex-machina-inquiry-form {
  height: 100%;
  border-top: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
}
.ex-machina-inquiry-form .inputs-container {
  padding: 2rem;
  padding-top: 1rem;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper {
  margin-bottom: 1rem;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper .label:after {
  content: ".";
  opacity: 0;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper .form-input.radio-input {
  display: flex;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper .form-input.radio-input .radio {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper .form-input.radio-input .radio label {
  vertical-align: top;
  padding-left: 0.5rem;
}
.ex-machina-inquiry-form .sales-contact-container {
  line-height: 1.4;
  padding: 1rem 3rem;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .name {
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .name .name-suffix {
  opacity: 0.3;
  padding-left: 0.5rem;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .name:after {
  content: ".";
  opacity: 0;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .info .phone {
  margin-bottom: 0.25rem;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .info .phone-suffix {
  opacity: 0.6;
  text-transform: uppercase;
  padding-left: 0.25rem;
  font-size: 80%;
  vertical-align: middle;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .info .email {
  display: block;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .thank-you-message {
  margin-top: 1rem;
  opacity: 0.3;
}
.app-navigation-list-item {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: opacity 0.9s, left 0.4s;
  transition: opacity 0.9s, left 0.4s;
  position: relative;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app-navigation-list-item.is-active > .list-item-target .list-item-target-text {
  color: rgb(119, 152, 173);
}
.app-navigation-list-item:not(.is-expanded) .list-item-children {
  display: none;
}
.app-navigation-list-item.space-before {
  margin-top: 1.5rem;
}
.app-navigation-list-item.list-item-level-0 .list-item-children {
  border-left: 1px solid rgba(70, 70, 70, 0.4);
}
.app-navigation-list-item:not(.is-visible) {
  opacity: 0;
  left: -1rem;
}
.app-navigation-list-item .list-item-target {
  display: flex;
  cursor: pointer;
}
.app-navigation-list-item .list-item-target .list-item-target-content {
  cursor: pointer;
}
.app-navigation-list-item .list-item-target .list-item-target-content .list-item-target-text {
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  display: inline-block;
  vertical-align: middle;
}
@media print, (min-device-width: 1025px) {
  .app-navigation-list-item .list-item-target {
    padding: 3px 2rem;
  }
  .app-navigation-list-item .list-item-target:hover {
    opacity: 0.6;
  }
  .app-navigation-list-item .list-item-children.has-children {
    margin: 0.5rem 0rem;
    margin-left: 3rem;
  }
}
@media only screen and (max-device-width: 1024px) {
  .app-navigation-list-item .list-item-target {
    padding: 0.3rem 1rem;
    font-size: 1.25rem;
  }
  .app-navigation-list-item .list-item-children.has-children {
    margin: 0.5rem 0rem;
    margin-left: 2rem;
  }
}
.app-navigation {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  height: 100%;
  -webkit-transition: width 0.4s opacity 0.5s;
  transition: width 0.4s opacity 0.5s;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.app-navigation a {
  -webkit-text-decoration: underline dotted rgba(0, 0, 0, 0.4);
          text-decoration: underline dotted rgba(0, 0, 0, 0.4);
}
.app-navigation.inquiry-form-is-visible .navigation-list {
  display: none;
  opacity: 0;
}
.app-navigation:not(.inquiry-form-is-visible) .inquiry-form-container {
  opacity: 0;
  display: none;
}
.app-navigation:not(.is-visible) .navigation-list {
  display: none;
  opacity: 0;
}
.app-navigation:not(.is-visible) .instagram-link {
  opacity: 0;
}
.app-navigation .app-navigation-icons-container {
  position: absolute;
  top: 0;
  background-color: #FFF;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container {
    height: 100%;
    width: 100%;
    border-right: 1px solid #e5e5e5;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-icons-container {
    display: flex;
    flex: 1 1;
    width: 100%;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    margin: auto;
    border-bottom: 1px solid #e5e5e5;
    z-index: 5;
  }
}
.app-navigation .app-navigation-icons-container .home-icon-container, .app-navigation .app-navigation-icons-container .navigation-icon-container, .app-navigation .app-navigation-icons-container .discuss-a-project-container {
  z-index: 2;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .home-icon-container, .app-navigation .app-navigation-icons-container .navigation-icon-container, .app-navigation .app-navigation-icons-container .discuss-a-project-container {
    position: absolute;
  }
}
.app-navigation .app-navigation-icons-container .home-icon-container img, .app-navigation .app-navigation-icons-container .navigation-icon-container img, .app-navigation .app-navigation-icons-container .discuss-a-project-container img {
  height: 100%;
  cursor: pointer;
}
.app-navigation .app-navigation-icons-container .home-icon-container {
  height: 1.2rem;
  width: 1.2rem;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .home-icon-container {
    top: 2rem;
    left: 1.9rem;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-icons-container .home-icon-container {
    display: none;
  }
}
.app-navigation .app-navigation-icons-container .home-icon-container:hover {
  opacity: 0.5;
}
.app-navigation .app-navigation-icons-container .navigation-icon-container {
  height: 2rem;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .navigation-icon-container {
    top: 50%;
    left: 1rem;
  }
}
.app-navigation .app-navigation-icons-container .navigation-icon-container img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}
.app-navigation .app-navigation-icons-container .navigation-icon-container img:hover {
  opacity: 0.2;
}
.app-navigation .app-navigation-icons-container .navigation-icon-container.hamburger {
  width: 3rem;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .navigation-icon-container.hamburger {
    left: 1rem;
  }
}
.app-navigation .app-navigation-icons-container .navigation-icon-container.close {
  width: 2rem;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .navigation-icon-container.close {
    left: 1.5rem;
  }
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .discuss-a-project-container {
    bottom: 0;
    padding: 1em 0;
    width: 5rem;
    border-top: 1px solid #e5e5e5;
    background-color: rgba(0, 0, 0, 0.9);
    color: #FFF;
  }
  .app-navigation .app-navigation-icons-container .discuss-a-project-container:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-icons-container .discuss-a-project-container {
    right: 0;
    padding: 2vw;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
}
.app-navigation .app-navigation-icons-container .discuss-a-project-container .discuss-a-project-text {
  text-align: center;
  text-transform: uppercase;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .discuss-a-project-container .discuss-a-project-text {
    font-size: 0.5em;
    letter-spacing: 0.2em;
    line-height: 2em;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-icons-container .discuss-a-project-container .discuss-a-project-text {
    font-size: 2.5vw;
    letter-spacing: 0.7vw;
    color: rgba(0, 0, 0, 0.75);
  }
}
.app-navigation .app-navigation-content {
  height: 100%;
  width: 100%;
  background-color: #FFF;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-content {
    padding-left: 5rem;
  }
}
.app-navigation .app-navigation-content .navigation-tray {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  z-index: 1;
}
.app-navigation .app-navigation-content .navigation-list {
  font-size: 1.2rem;
  position: absolute;
  z-index: 2;
  overflow: auto;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-content .navigation-list {
    width: calc(100% - 5rem);
    height: 100%;
    padding-top: calc(2rem - 6px);
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-content .navigation-list {
    top: 4rem;
    height: calc(100% - 4rem);
    width: 100%;
    padding-top: 1rem;
  }
}
.app-navigation .app-navigation-content .navigation-list .instagram-link {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  padding: 2px 2rem;
}
@media only screen and (max-device-width: 1024px) {
  .app-navigation .app-navigation-content .navigation-list .instagram-link {
    padding: 2px 1rem;
  }
}
.app-navigation .app-navigation-content .navigation-list .instagram-link a img {
  width: 18px;
  opacity: 0.7;
}
.app-navigation .app-navigation-content .navigation-list .instagram-link a img:hover {
  opacity: 0.4;
}
.app-navigation .app-navigation-content .inquiry-form-container {
  z-index: 3;
  width: 100%;
  height: 100%;
  position: relative;
  border-left: 1px solid #e5e5e5;
  -webkit-animation: showHideForm 0.5s;
          animation: showHideForm 0.5s;
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-content .inquiry-form-container {
    padding-top: 4rem;
  }
}
@-webkit-keyframes showHideForm {
  0% {
    opacity: 0;
    display: none;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
@keyframes showHideForm {
  0% {
    opacity: 0;
    display: none;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
.app-navigation .app-navigation-content .inquiry-form-container .hide-inquiry-form {
  font-size: 1.2rem;
  width: 4rem;
  cursor: pointer;
  padding: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-content .inquiry-form-container .hide-inquiry-form {
    margin-left: 0rem;
    padding-left: 2rem;
  }
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input {
  display: flex;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio {
  flex-grow: 1;
  margin-bottom: 0;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio input {
  display: none;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio label {
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  text-align: center;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio.checked label {
  color: rgb(119, 152, 173);
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-message {
  border-top: 1px solid #e5e5e5;
  padding: 1rem;
  text-align: center;
  color: crimson;
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-message {
    font-size: 75%;
  }
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-iframe-container {
  width: 100%;
  height: calc(100% - 3.5rem);
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  border-top: 1px solid #e5e5e5;
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-iframe-container .inquiry-form-iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}
.app-navigation .app-navigation-content .inquiry-form-container .showrooms-info-container {
  border-top: 1px solid #e5e5e5;
}
.app-navigation .app-navigation-content .inquiry-form-container .showrooms-info-container .info-block {
  line-height: 1.4;
  padding: 1rem 2rem;
  max-width: 20rem;
}
.app-navigation .app-navigation-content .inquiry-form-container .showrooms-info-container .info-block a {
  text-decoration: underline;
}
.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.7;
}

.theme-light .button, .theme-light .text-input input {
  font-size: 0.9rem;
}
.theme-light .button, .theme-light .text-input input, .theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  border: 1px solid #ddd;
}
.theme-light .text-input input {
  width: 100%;
}
.theme-light label, .theme-light .label {
  padding: 0.4rem 1rem;
  padding-top: 0.6rem;
}

.subscribe-form .ui.form {
  display: flex;
}
.subscribe-form .subscribe-form-thank-you-message {
  width: 100%;
  margin: auto;
  padding-left: 1rem;
}
.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.7;
}

.theme-light .button, .theme-light .text-input input {
  font-size: 0.9rem;
}
.theme-light .button, .theme-light .text-input input, .theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  border: 1px solid #ddd;
}
.theme-light .text-input input {
  width: 100%;
}
.theme-light label, .theme-light .label {
  padding: 0.4rem 1rem;
  padding-top: 0.6rem;
}

.inquiry-form {
  height: 100%;
}
.inquiry-form .radio-input {
  display: block;
}
.inquiry-form .thank-you-message {
  padding: 2rem;
}
@media print, (min-device-width: 1025px) {
  .inquiry-form .form-and-prompt-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.inquiry-form .form-and-prompt-wrapper .inquiry-form-prompt {
  line-height: 1.4;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e5e5e5;
}
.inquiry-form .form-and-prompt-wrapper .inquiry-form-prompt .prompt-text {
  max-width: 35rem;
}
.inquiry-form .form-and-prompt-wrapper .form-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.inquiry-form .form-and-prompt-wrapper .form-wrapper form {
  padding: 2rem;
  padding-top: 1rem;
}
.inquiry-form .form-input .dropdown .item .text {
  white-space: normal !important;
}
@media only screen and (min-width: 301px) {
  .inquiry-form .form-input:nth-child(2n+1) {
    margin-right: 1rem;
  }
}
@media only screen and (max-width: 300px) {
  .inquiry-form .form-input {
    width: 100%;
  }
}
.register-employee {
  display: flex;
  height: 100%;
}
.register-employee .register-employee-content-container {
  margin: auto;
  text-align: center;
}
.register-employee .register-employee-content-container .register-employee-message {
  color: rgb(119, 152, 173);
  margin-bottom: 2rem;
}
.register-employee .register-employee-content-container .redirect-message .click-here-link {
  opacity: 0.8;
}
@media only screen and (max-width: 800px) {
  .register-employee {
    width: calc(100% - 2rem);
  }
  .register-employee .register-employee-message {
    font-size: 1.2rem;
  }
}
@media print, (min-width: 801px) {
  .register-employee {
    width: calc(100% - 5rem);
  }
  .register-employee .register-employee-message {
    font-size: 1.8rem;
  }
}
#root {
  overflow-x: hidden;
}
#root:not(.landing-page) {
  height: 100%;
  min-height: 100%;
}

.app {
  position: relative;
  left: 0px;
}
.app:not(.landing-page) {
  height: 100%;
  min-height: 100%;
}
.app, .app .navigation-tray {
  -webkit-transition: left 0.4s;
  transition: left 0.4s;
}
.app .app-content {
  height: 100%;
  z-index: 0;
}
.app .app-404 {
  width: 100%;
  height: 100%;
}
.app .app-404 .text-404 {
  text-align: center;
  position: relative;
  top: calc(50% - 0.7rem);
}
@media print, (min-width: 801px) {
  .app.showing-navigation .app-navigation {
    width: 50%;
  }
  .app.showing-navigation .app-navigation .navigation-tray {
    left: 0px;
  }
}
@media print, print and (min-width: 801px), print and (min-width: 801px), (min-width: 801px) and (min-width: 801px) {
  .app:not(.showing-navigation) .app-navigation {
    width: 5rem;
  }
}
@media only screen and (min-width: 801px) and (max-width: 800px) {
  .app:not(.showing-navigation) .app-navigation {
    width: 100%;
    height: 4rem;
  }
}
@media print, (min-width: 801px) {
  .app:not(.showing-navigation) .app-navigation .list-item-target .list-item-target-text {
    opacity: 0;
  }
  .app:not(.showing-navigation) .app-navigation .navigation-tray {
    left: 0;
  }
  .app .app-content {
    padding-left: 5rem;
  }
  .app .gdpr-banner {
    right: 0;
    left: auto;
  }
}
@media only screen and (max-width: 800px) {
  .app.showing-navigation .app-navigation {
    width: 100%;
  }
  .app.showing-navigation .app-navigation .navigation-tray {
    left: 0px;
  }
}
@media only screen and (max-width: 800px) and (min-width: 801px) {
  .app:not(.showing-navigation) .app-navigation {
    width: 5rem;
  }
}
@media only screen and (max-width: 800px) and (max-width: 800px) {
  .app:not(.showing-navigation) .app-navigation {
    width: 100%;
    height: 4rem;
  }
}
@media only screen and (max-width: 800px) {
  .app:not(.showing-navigation) .app-navigation .list-item-target .list-item-target-text {
    opacity: 0;
  }
  .app:not(.showing-navigation) .app-navigation .navigation-tray {
    left: 0;
  }
  .app .app-content {
    padding-top: 4rem;
  }
}
@media print {
  @page {
    margin: 0;
    orientation: landscape;
    size: 11in 8.5in;
  }
  html, body, .app-content, .app-page {
    height: auto !important;
    min-height: auto !important;
  }
  .app .app-navigation {
    display: none;
  }
  .app .app-content {
    padding-left: 0;
  }
  .app .app-content .app-page .page-sections {
    overflow: inherit !important;
  }
  .app .app-content .app-page .page-sections .page-section {
    page-break-after: always;
    border-bottom: none;
  }
  .app .app-content .app-page .page-sections .page-section.layout-text-left-grid-right .grid-cell {
    width: 8rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .app .app-content .app-page .page-sections .page-section.layout-text-left-grid-right .cell-group:not(:first-child) .grid-cell {
    margin-top: 3rem;
  }
  .app .app-content .app-page .page-sections .page-section:not(.layout-text-left-grid-right) {
    height: 8.5in;
  }
  .app .app-content .app-page .page-sections .page-section .page-section-logo-text {
    display: none;
  }
}
.hb-cv-message {
  line-height: 1.4;
  color: #666;
}
.hb-cv-message a {
  color: #333;
  text-decoration: underline;
}
@media screen and (min-width: 801px) {
  .hb-cv-message {
    max-width: 42rem;
    padding: 5rem;
  }
}
@media screen and (max-width: 800px) {
  .hb-cv-message {
    font-size: 0.8rem;
  }
}

.hb-cv-message {
  line-height: 1.4;
  color: #666;
}
.hb-cv-message a {
  display: inline-block;
  vertical-align: middle;
}
@media screen and (min-width: 801px) {
  .hb-cv-message {
    max-width: 44rem;
    padding: 5rem 10rem;
    font-size: 2rem;
  }
  .hb-cv-message span {
    font-size: 1.7rem;
  }
  .hb-cv-message img {
    width: 2.5rem;
  }
}
@media screen and (max-width: 800px) {
  .hb-cv-message {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .hb-cv-message span {
    font-size: 1rem;
  }
  .hb-cv-message img {
    width: 2.5rem;
  }
}

.popup .popup-window {
  border-radius: 0px;
  box-sizing: border-box;
  position: relative;
  height: auto;
}
.popup .popup-window .popup-close-button {
  cursor: pointer;
  width: 2rem !important;
  height: 2rem !important;
  display: block;
  opacity: 0.6;
}
@media screen and (min-width: 801px) {
  .popup .popup-window .close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .popup .popup-window {
    padding: 2rem;
    width: calc(100% - 2rem) !important;
  }
  .popup .popup-window .close-button {
    margin-left: auto;
    position: relative;
    top: -1rem;
    left: 0.5rem;
  }
}
.popup.type-fitted .popup-window {
  background-color: #eee;
}
@media screen and (min-width: 801px) {
  .popup.type-fitted .popup-window .popup-content {
    padding: 1.5rem 2.5rem;
  }
}
.popup.type-fitted .popup-window .popup-content .inquiry-popup {
  text-align: center;
}
.popup.type-fitted .popup-window .popup-content .inquiry-popup .inquiry-popup-tagline {
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(119, 152, 173);
  margin-bottom: 1rem;
}
.popup.type-fitted .popup-window .popup-content .inquiry-popup .inquiry-popup-message {
  margin-bottom: 2rem;
}
.popup.type-corner .popup-window {
  background-color: #eee;
}
@media screen and (min-width: 801px) {
  .popup.type-corner .popup-window {
    width: 22rem;
  }
  .popup.type-corner .popup-window.position-left {
    left: 7rem !important;
  }
}
.popup.type-corner .popup-window .popup-content {
  font-size: 0.8rem;
  line-height: 1.4;
}
@media screen and (min-width: 801px) {
  .popup.type-corner .popup-window .popup-content {
    padding: 1.5rem 2.5rem;
  }
}
.popup.type-corner .popup-window .popup-content .subscribe-popup .subscribe-popup-tagline, .popup.type-corner .popup-window .popup-content .subscribe-popup .subscribe-popup-message {
  margin-bottom: 1em;
}
.popup.type-corner .popup-window .popup-content .subscribe-popup .subscribe-popup-tagline {
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(119, 152, 173);
}

.subscribe-form-container {
  height: 2.8rem;
  display: flex;
}
.subscribe-form-container iframe {
  border: none;
  width: 100%;
}
.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.7;
}

.theme-light .button, .theme-light .text-input input {
  font-size: 0.9rem;
}
.theme-light .button, .theme-light .text-input input, .theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  border: 1px solid #ddd;
}
.theme-light .text-input input {
  width: 100%;
}
.theme-light label, .theme-light .label {
  padding: 0.4rem 1rem;
  padding-top: 0.6rem;
}
