.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.7;
}

.theme-light .button, .theme-light .text-input input {
  font-size: 0.9rem;
}
.theme-light .button, .theme-light .text-input input, .theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  border: 1px solid #ddd;
}
.theme-light .text-input input {
  width: 100%;
}
.theme-light label, .theme-light .label {
  padding: 0.4rem 1rem;
  padding-top: 0.6rem;
}

.ex-machina-inquiry-form {
  height: 100%;
  border-top: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
}
.ex-machina-inquiry-form .inputs-container {
  padding: 2rem;
  padding-top: 1rem;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper {
  margin-bottom: 1rem;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper .label:after {
  content: ".";
  opacity: 0;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper .form-input.radio-input {
  display: flex;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper .form-input.radio-input .radio {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}
.ex-machina-inquiry-form .inputs-container .input-wrapper .form-input.radio-input .radio label {
  vertical-align: top;
  padding-left: 0.5rem;
}
.ex-machina-inquiry-form .sales-contact-container {
  line-height: 1.4;
  padding: 1rem 3rem;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .name {
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .name .name-suffix {
  opacity: 0.3;
  padding-left: 0.5rem;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .name:after {
  content: ".";
  opacity: 0;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .info .phone {
  margin-bottom: 0.25rem;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .info .phone-suffix {
  opacity: 0.6;
  text-transform: uppercase;
  padding-left: 0.25rem;
  font-size: 80%;
  vertical-align: middle;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .info .email {
  display: block;
}
.ex-machina-inquiry-form .sales-contact-container .sales-contact .thank-you-message {
  margin-top: 1rem;
  opacity: 0.3;
}